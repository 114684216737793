import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, Eip1193Provider, ethers } from 'ethers';
import axios from "axios";
import { socket } from "../utils/socket";
import { getContractAddress, getContractABI } from "../utils/contractUtils";
import { styled, Theme } from "@mui/material/styles";
import {
    POLYGON_LP_CONTRACT_ADDRESS,
    POLYGON_LIN_CONTRACT_ADDRESS,
    POLYGON_LOG_CONTRACT_ADDRESS,
    POLYGON_EXP_CONTRACT_ADDRESS
  } from "../abis/FairLaunchFactoryAddress";

const pinataApiKey = 'd94f8086b76748073f77';
const pinataSecretApiKey = '42117f4fb179600ed6cb9fa75ba0096682f52f976352aaadc810f23dee204d88';

const EXPECTED_INITIAL_AMOUNT = '0.1';

const CyberBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'var(--background-color)',
    color: 'var(--text-color)',
    padding: theme.spacing(3),
    borderRadius: '10px',
    border: '2px solid var(--primary-color)',
    boxShadow: 'var(--neon-glow)',
  }));
  
  const CyberTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Orbitron, sans-serif',
    color: 'var(--primary-color)',
    textShadow: 'var(--neon-glow)',
    textTransform: 'uppercase',
  }));
  
  const CyberTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primary-color)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--secondary-color)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--accent-color)',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'var(--primary-color)',
    },
    '& .MuiInputBase-input': {
      color: 'var(--text-color)',
    },
  }));
  
  // Updated CyberButton with explicit 'component' prop type
  const CyberButton = styled(Button)<{ component?: React.ElementType }>(() => ({
    background: 'linear-gradient(45deg, var(--primary-color), var(--secondary-color))',
    border: 'none',
    borderRadius: '5px',
    color: 'var(--background-color)',
    fontFamily: 'Orbitron, sans-serif',
    fontWeight: 'bold',
    padding: '10px 20px',
    textTransform: 'uppercase',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'linear-gradient(45deg, var(--secondary-color), var(--primary-color))',
      boxShadow: '0 0 10px var(--primary-color)',
    },
  }));
  
  const CyberCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'var(--card-background)',
    border: '2px solid var(--primary-color)',
    borderRadius: '10px',
    boxShadow: '0 0 10px var(--primary-color)',
  }));
  
  const CyberCardContent = styled(CardContent)(({ theme }) => ({
    color: 'var(--text-color)',
  }));

export default function CreateTokenPage() {
    const navigate = useNavigate();
    const [deployModal, setDeployModal] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { open } = useWeb3Modal();

    const [coinName, setCoinName] = useState("");
    const [coinTicker, setCoinTicker] = useState("");
    const [description, setDescription] = useState("");
    const [imageLink, setImageLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [telegramLink, setTelegramLink] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const [webLink, setWebLink] = useState('');
    const [signer, setSigner] = useState<ethers.Signer | null>(null);
    const [fairLaunchContract, setFairLaunchContract] = useState<Contract | null>(null);
    const [nativeBalanceString, setNativeBalanceString] = useState("");
    const [initBuyAmount, setInitBuyAmount] = useState(EXPECTED_INITIAL_AMOUNT);
    const [minDeploymentAmount, setMinDeploymentAmount] = useState(EXPECTED_INITIAL_AMOUNT);
    const [pricingMethod, setPricingMethod] = useState<string>('reserve-ratio');
    const [contractAddress, setContractAddress] = useState(POLYGON_LP_CONTRACT_ADDRESS);

    const handleClickOpen = () => {
        if (!isConnected) {
            open();
            return;
        }
        setDeployModal(true);
    };

    const tokenDeploy = async () => {
        if (!isConnected || !fairLaunchContract) {
            alert("Please connect wallet and ensure contract is initialized");
            return;
        }
    
        try {
            console.log("Deploying token with parameters:", {
                name: coinName,
                symbol: coinTicker,
                value: ethers.parseEther(initBuyAmount).toString()
            });
    
            const tx = await fairLaunchContract.createToken(
                coinName, 
                coinTicker,
                { value: ethers.parseEther(initBuyAmount) }
            );
            
            console.log("Transaction sent:", tx.hash);
            
            const receipt = await tx.wait();
            console.log("Transaction receipt:", receipt);
            
            let tokenAddress;
            const tokenCreatedEvent = receipt.logs.find((log: any) => {
                try {
                    const parsedLog = fairLaunchContract.interface.parseLog(log);
                    return parsedLog && parsedLog.name === "TokenCreated";
                } catch (e) {
                    return false;
                }
            });
    
            if (!tokenCreatedEvent) {
                console.warn("TokenCreated event not found in logs. Falling back to contract call.");
                tokenAddress = await fairLaunchContract.getLastCreatedToken();
                if (!tokenAddress) {
                    throw new Error("Failed to retrieve token address");
                }
                console.log("Retrieved token address:", tokenAddress);
            } else {
                const parsedEvent = fairLaunchContract.interface.parseLog(tokenCreatedEvent);
                tokenAddress = parsedEvent ? parsedEvent.args[0] : null;
                console.log("Token address from event:", tokenAddress);
            }
    
            const tokenMarketCap = await fairLaunchContract.getTokenMarketCap(tokenAddress);
            const tokenPrice = await fairLaunchContract.getPrice(tokenAddress);
    
            // Note: We're not calculating tokenPriceUSD here as we don't have the MATIC price
            // If you need this value, consider fetching it from an external API
    
            const body = {
                type: "createToken",
                data: {
                    tokenName: coinName,
                    tokenSymbol: coinTicker,
                    tokenAddress: tokenAddress,
                    tokenImage: imageLink,
                    marketcap: tokenMarketCap.toString(),
                    price: ethers.formatEther(tokenPrice), // Convert to human-readable format
                    virtualLP: initBuyAmount, // Use the initial buy amount as virtualLP
                    creatorAddress: address,
                    network: "Polygon",
                    replies: 0,
                    telegramLink,
                    twitterLink,
                    webLink,
                    description: description,
                    contracttype: getPricingMethodNumber(pricingMethod)
                }
            };
    
            socket.emit("message", JSON.stringify(body));
    
            setDeployModal(false);
            navigate('/my_Tokens');
        } catch (error) {
            console.error("Error deploying token:", error);
            if ((error as any).reason) {
                alert(`Error deploying token: ${(error as any).reason}`);
            } else {
                alert("Error deploying token. Please check console for details.");
            }
        }
    };

    const handleClose = () => {
        setDeployModal(false);
    };

    const uploadImageToIPFS = async (file: File) => {
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let data = new FormData();
        data.append('file', file);

        const headers = {
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataSecretApiKey,
            'Content-Type': 'multipart/form-data'
        };

        try {
            const response = await axios.post(url, data, { headers });
            return response.data;
        } catch (error) {
            console.error('Error uploading file: ', error);
            return null;
        }
    };

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setIsLoading(true);
            const file = event.target.files[0];
            const result = await uploadImageToIPFS(file);
            if (result) {
                setImageLink(result.IpfsHash);
            }
            setIsLoading(false);
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (input.length <= 140) {
            setDescription(input);
        }
    };

    const CyberSelect = styled(Select)(({ theme }) => ({
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--primary-color)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--secondary-color)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--accent-color)',
        },
        '& .MuiSelect-icon': {
          color: 'var(--primary-color)',
        },
        '& .MuiSelect-select': {
          color: 'var(--text-color)',
        },
      }));
      
      const CyberFormControl = styled(FormControl)(({ theme }) => ({
        '& .MuiInputLabel-root': {
          color: 'var(--primary-color)',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'var(--accent-color)',
        },
      }));

      useEffect(() => {
        const init = async () => {
            if (!walletProvider) return;

            try {
                const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider);
                const tempSigner = await ethersProvider.getSigner();
                setSigner(tempSigner);

                if (chainId !== 137) {
                    alert("Please connect to Polygon network");
                    return;
                }

                const realABI = getContractABI(pricingMethod);
                const tempFairLaunchContract = new Contract(contractAddress, realABI, tempSigner);
                setFairLaunchContract(tempFairLaunchContract);

                const nativeBalance = await tempSigner.provider.getBalance(tempSigner.address);
                setNativeBalanceString(ethers.formatEther(nativeBalance));

                console.log("Contract ABI:", JSON.stringify(tempFairLaunchContract.interface.fragments, null, 2));

                try {
                    const initialAmountUD60x18 = await tempFairLaunchContract.INITIAL_AMOUNT();
                    const initialAmountEther = ethers.formatUnits(initialAmountUD60x18, 18);
                    console.log("INITIAL_AMOUNT fetched from contract:", initialAmountEther);

                    if (initialAmountEther === '0.0' || parseFloat(initialAmountEther) === 0) {
                        console.warn("Fetched INITIAL_AMOUNT is 0.0. Using expected value:", EXPECTED_INITIAL_AMOUNT);
                        setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                        setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
                    } else {
                        console.log("Using fetched INITIAL_AMOUNT:", initialAmountEther);
                        setMinDeploymentAmount(initialAmountEther);
                        setInitBuyAmount(initialAmountEther);
                    }
                } catch (error) {
                    console.error("Error fetching INITIAL_AMOUNT:", error);
                    console.log("Using default INITIAL_AMOUNT:", EXPECTED_INITIAL_AMOUNT);
                    setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                    setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
                }

            } catch (error) {
                console.error("Error in init:", error);
                setMinDeploymentAmount(EXPECTED_INITIAL_AMOUNT);
                setInitBuyAmount(EXPECTED_INITIAL_AMOUNT);
            }
        };
        init();
    }, [chainId, address, walletProvider, contractAddress, pricingMethod]);


      const handlePricingMethodChange = (event: SelectChangeEvent<unknown>) => {
        const method = event.target.value as string;
        setPricingMethod(method);
        switch (method) {
            case 'reserve-ratio':
                setContractAddress(POLYGON_LP_CONTRACT_ADDRESS);
                break;
            case 'linear':
                setContractAddress(POLYGON_LIN_CONTRACT_ADDRESS);
                break;
            case 'logarithmic':
                setContractAddress(POLYGON_LOG_CONTRACT_ADDRESS);
                break;
            case 'exponential':
                setContractAddress(POLYGON_EXP_CONTRACT_ADDRESS);
                break;
            default:
                setContractAddress(POLYGON_LP_CONTRACT_ADDRESS);
        }
    };

    const getPricingMethodNumber = (method: string): number => {
        switch (method) {
          case 'reserve-ratio':
            return 0;
          case 'linear':
            return 1;
          case 'logarithmic':
            return 2;
          case 'exponential':
            return 3;
          default:
            return 0; // Default to reserve ratio
        }
      };

    return (
        <CyberBox>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} sm={6} mx='auto'>
              <CyberBox sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
                <CyberTypography variant="h4" gutterBottom>Create a New Memecoin on Polygon</CyberTypography>
                <CyberFormControl fullWidth margin="normal">
              <InputLabel id="pricing-method-label">Pricing Method</InputLabel>
              <CyberSelect
                    labelId="pricing-method-label"
                    value={pricingMethod}
                    onChange={handlePricingMethodChange}
                    label="Pricing Method"
                    >
                <MenuItem value="reserve-ratio">Reserve Ratio</MenuItem>
                <MenuItem value="linear">Linear Bonding Curve</MenuItem>
                <MenuItem value="logarithmic">Logarithmic Bonding Curve</MenuItem>
                <MenuItem value="exponential">Exponential Bonding Curve</MenuItem>
              </CyberSelect>
            </CyberFormControl>
                <CyberTextField label="Name" variant="outlined" fullWidth value={coinName} onChange={(e) => setCoinName(e.target.value)} margin="normal" />
                <CyberTextField label="Ticker" variant="outlined" fullWidth value={coinTicker} onChange={(e) => setCoinTicker(e.target.value)} margin="normal" />
                <CyberTextField 
                  label="Description" 
                  variant="outlined" 
                  multiline 
                  rows={4} 
                  fullWidth 
                  value={description} 
                  onChange={handleDescriptionChange} 
                  margin="normal"
                  inputProps={{ maxLength: 140 }}
                  helperText={`${description.length}/140 characters`}
                />                <CyberButton
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  Upload Image
                  <input type="file" hidden onChange={onFileChange} accept="image/*" />
                </CyberButton>
                {imageLink && <CyberTypography>Image uploaded successfully</CyberTypography>}
                {isLoading && <CyberTypography>Uploading image...</CyberTypography>}
                <CyberTextField label="Telegram Link" variant="outlined" fullWidth value={telegramLink} onChange={(e) => setTelegramLink(e.target.value)} margin="normal" />
                <CyberTextField label="Twitter Link" variant="outlined" fullWidth value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} margin="normal" />
                <CyberTextField label="Website Link" variant="outlined" fullWidth value={webLink} onChange={(e) => setWebLink(e.target.value)} margin="normal" />
                <CyberTextField 
                  label="Deploy Fee (MATIC)" 
                  variant="outlined" 
                  fullWidth 
                  value={initBuyAmount} 
                  onChange={(e) => setInitBuyAmount(e.target.value)} 
                  margin="normal" 
                  type="number"
                  helperText={`Minimum: ${minDeploymentAmount} MATIC`}
                />
                <CyberButton variant="contained" color="primary" fullWidth onClick={handleClickOpen} sx={{ marginTop: 2 }}>
                  {isConnected ? "Deploy Memecoin" : "Connect Wallet"}
                </CyberButton>
              </CyberBox>
            </Grid2>
          </Grid2>
          <Dialog open={deployModal} onClose={handleClose} PaperProps={{ style: { backgroundColor: 'var(--background-color)', border: '2px solid var(--primary-color)' } }}>
            <DialogTitle><CyberTypography>Confirm Deployment</CyberTypography></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <CyberTypography>Are you sure you want to deploy your token with the following details?</CyberTypography>
              </DialogContentText>
              <CyberCard>
                <CyberCardContent>
                  <CyberTypography>Name: {coinName}</CyberTypography>
                  <CyberTypography>Ticker: {coinTicker}</CyberTypography>
                  <CyberTypography>Deploy Fee: {initBuyAmount} MATIC</CyberTypography>
                  <CyberTypography>Your Balance: {nativeBalanceString} MATIC</CyberTypography>
                </CyberCardContent>
              </CyberCard>
            </DialogContent>
            <DialogActions>
              <CyberButton onClick={handleClose} color="primary">Cancel</CyberButton>
              <CyberButton onClick={tokenDeploy} color="primary">Confirm & Deploy</CyberButton>
            </DialogActions>
          </Dialog>
        </CyberBox>
      );
    }