import React from 'react';
import { Link } from "react-router-dom";
import { MainRouter } from "../../routers";
import Logo from '../../assets/images/logo.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useMainContext } from "../../context/mainContext";
import { 
  Box, Divider, Drawer, IconButton, List, ListItem, 
  ListItemButton, ListItemText, Toolbar, styled, Typography  
} from "@mui/material";

const drawerWidth = 240;

const CyberDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: 'linear-gradient(45deg, #1C2132, #2a3a5a)',
    borderRight: '2px solid var(--primary-color)',
    boxShadow: '0 0 10px var(--primary-color)',
  },
}));

const CyberListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    background: 'rgba(0, 255, 255, 0.1)',
  },
}));

const NeonText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: 'var(--primary-color)',
    fontFamily: 'Orbitron, sans-serif',
    textShadow: '0 0 5px var(--primary-color)',
  },
}));

const CyberIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--primary-color)',
  '&:hover': {
    color: 'var(--secondary-color)',
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  color: 'var(--secondary-color)',
  fontFamily: 'Orbitron, sans-serif',
  fontSize: '2rem',
  fontWeight: 'bold',
  textShadow: '0 0 10px var(--secondary-color)',
  marginRight: 'auto',
}));

const keyframes = `
  @keyframes glitch-skew {
    0%, 80%, 100% { transform: skew(0deg); }
    85% { transform: skew(2deg); }
    95% { transform: skew(-2deg); }
  }

  @keyframes glitch-color1 {
    0%, 90%, 100% { opacity: 0; filter: none; }
    92% { opacity: 0.8; filter: drop-shadow(-5px 0 rgba(0, 255, 255, 0.7)) brightness(1.5); }
    94% { opacity: 0.7; filter: drop-shadow(5px 0 rgba(255, 0, 255, 0.7)) brightness(1.5); }
    96% { opacity: 0.8; filter: drop-shadow(-5px 0 rgba(255, 255, 0, 0.7)) brightness(1.5); }
    98% { opacity: 0.7; filter: drop-shadow(5px 0 rgba(0, 255, 255, 0.7)) brightness(1.5); }
  }

  @keyframes glitch-color2 {
    0%, 90%, 100% { opacity: 0; filter: none; }
    92% { opacity: 0.7; filter: drop-shadow(5px 0 rgba(255, 0, 255, 0.7)) brightness(1.5); }
    94% { opacity: 0.8; filter: drop-shadow(-5px 0 rgba(255, 255, 0, 0.7)) brightness(1.5); }
    96% { opacity: 0.7; filter: drop-shadow(5px 0 rgba(0, 255, 255, 0.7)) brightness(1.5); }
    98% { opacity: 0.8; filter: drop-shadow(-5px 0 rgba(255, 0, 255, 0.7)) brightness(1.5); }
  }

  @keyframes glitch-clip {
    0%, 90%, 100% { clip-path: inset(0 0 0 0); }
    92% { clip-path: inset(80% 0 0 0); }
    94% { clip-path: inset(0 15% 85% 0); }
    96% { clip-path: inset(0 0 80% 0); }
    98% { clip-path: inset(15% 85% 0 0); }
  }

  @keyframes neon-glow {
    0%, 100% { filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.7)) drop-shadow(0 0 10px rgba(0, 255, 255, 0.7)) drop-shadow(0 0 15px rgba(0, 255, 255, 0.7)); }
    33% { filter: drop-shadow(0 0 5px rgba(255, 0, 255, 0.7)) drop-shadow(0 0 10px rgba(255, 0, 255, 0.7)) drop-shadow(0 0 15px rgba(255, 0, 255, 0.7)); }
    66% { filter: drop-shadow(0 0 5px rgba(255, 255, 0, 0.7)) drop-shadow(0 0 10px rgba(255, 255, 0, 0.7)) drop-shadow(0 0 15px rgba(255, 255, 0, 0.7)); }
  }
`;

const CyberpunkLogo = styled('div')(({ theme }) => ({
  width: `${drawerWidth + 40}px`,
  height: `${(drawerWidth + 40) * 0.75}px`,
  position: 'relative',
  overflow: 'visible',
  margin: '1rem -20px',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    right: '-10px',
    bottom: '-10px',
    backgroundImage: `url(${Logo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 2,
    opacity: 0.7,
  },
  '&::before': {
    animation: 'glitch-color1 10s step-end infinite, glitch-clip 10s infinite',
  },
  '&::after': {
    animation: 'glitch-color2 10s step-end infinite, glitch-clip 10s infinite 0.5s',
  },
  '& img': {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.5,
    animation: 'glitch-skew 10s infinite, neon-glow 15s infinite alternate',
  },
}));

const LogoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '1rem',
  overflow: 'visible',
  '@media (max-width: 600px)': {
    marginTop: '0.5rem',
  },
});

interface SidebarProps {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function Sidebar({ window, mobileOpen, handleDrawerToggle }: SidebarProps) {
  const container = window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    // Inject the keyframes
    const style = document.createElement('style');
    style.innerHTML = keyframes;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const drawer = (
    <Box>
      <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent:'center', paddingTop: '1rem', paddingBottom: '0.5rem' }}>
        <Link to="/">
          <LogoText>
            LOWCAP
          </LogoText>
        </Link>        
        <CyberIconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: "0.5rem", marginBottom: '0.5rem', display: { sm: 'none' } }}
        >
          <MenuOpenIcon />
        </CyberIconButton>
      </Toolbar>
      <Divider sx={{ backgroundColor: 'var(--primary-color)', width: '80%', margin: 'auto' }} />
      <List>
        {MainRouter.main.map((router, index) => (
          <Link key={index} to={router.path} style={{ textDecoration: 'none' }}>
            <CyberListItem>
              <ListItemButton sx={{ borderRadius: 50 }}>
                <NeonText primary={router.title} />
              </ListItemButton>
            </CyberListItem>
          </Link>
        ))}
      </List>
      <Divider sx={{ backgroundColor: 'var(--primary-color)', width: '80%', margin: 'auto' }} />
      <LogoContainer>
        <CyberpunkLogo>
          <img src={Logo} alt="logo" />
        </CyberpunkLogo>
      </LogoContainer>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <CyberDrawer
        variant="temporary"
        container={container}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { 
            width: '80%', 
            maxWidth: '300px',
          },
        }}
      >
        {drawer}
      </CyberDrawer>
      <CyberDrawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
        open
      >
        {drawer}
      </CyberDrawer>
    </Box>
  );
}

export default Sidebar;