import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, CardMedia, Typography, Box, Chip, 
  LinearProgress, IconButton, Tooltip, styled, Dialog, DialogContent, DialogTitle
} from '@mui/material';
import { Language, Telegram, Twitter } from '@mui/icons-material';

interface TokenData {
  marketcap: number;
  virtualLP: number;
  price: number;
  percentageSold: number;
}

interface MainCardProps {
  network: string;
  image: string;
  title: string;
  ticker: string;
  market_cap: number;
  trades: number;
  deployer: string;
  web: string;
  telegram: string;
  twitter: string;
  percentageSold: number;
  tokenData: TokenData;
  createdAt?: string | Date;
  recentTransactions?: number;
  description?: string;
  contracttype: number;
  onDescriptionClick: (event: React.MouseEvent) => void;
  isMobile: boolean;
}

const CyberCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
  border: '2px solid var(--primary-color)',
  borderRadius: '10px',
  boxShadow: '0 0 10px var(--primary-color), inset 0 0 10px var(--primary-color)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 0 20px var(--primary-color), inset 0 0 20px var(--primary-color)',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const NeonText = styled(Typography)(({ theme }) => ({
  color: 'var(--primary-color)',
  textShadow: '0 0 5px var(--primary-color), 0 0 10px var(--primary-color), 0 0 15px var(--primary-color), 0 0 20px var(--primary-color)',
}));

const CyberChip = styled(Chip)<{ chiptype: 'STATUS' | 'CONTRACT' }>(({ theme, chiptype }) => ({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  backgroundColor: 
    chiptype === 'STATUS' 
      ? (theme.palette.mode === 'dark' ? '#FFD700' : '#FF1493')  // Gold for dark mode, Deep Pink for light mode
      : '#00FFFF',  // Cyan for CONTRACT
  color: '#000',
  marginRight: '5px',
}));

const CyberProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#1a1a1a',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    background: 'linear-gradient(90deg, var(--primary-color), var(--secondary-color))',
  },
}));

const CyberIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--primary-color)',
  '&:hover': {
    color: 'var(--secondary-color)',
    background: 'rgba(0, 255, 255, 0.1)',
  },
}));

const MainCard: React.FC<MainCardProps> = ({ 
  network, image, title, ticker, market_cap, trades, deployer, web, telegram, twitter, 
  percentageSold, tokenData, createdAt, recentTransactions, description, contracttype, isMobile,
  onDescriptionClick
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [debugInfo, setDebugInfo] = useState<string>('');

  const formatNumber = (num: number) => {
    if (isNaN(num) || num === 0) return '0';
    if (num >= 1e9) return (num / 1e9).toFixed(2) + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(2) + 'K';
    return num.toFixed(2);
  };

  const formatPrice = (price: number) => {
    if (isNaN(price) || price === 0) return '0';
    
    const priceString = price.toFixed(18);
    const [integerPart, decimalPart] = priceString.split('.');
    
    if (integerPart !== '0') {
      return price.toFixed(4);
    }

    const leadingZeros = decimalPart.match(/^0+/)?.[0].length || 0;
    const significantDigits = decimalPart.slice(leadingZeros, leadingZeros + 4);

    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        0.0
        <sub style={{ fontSize: '0.7em', verticalAlign: 'bottom' }}>{leadingZeros}</sub>
        {significantDigits}
      </span>
    );
  };

  const formatPercentage = (percent: number) => {
    return Math.min(Math.max(percent, 0), 100).toFixed(2);
  };

  const parseCreatedAt = (dateString: string | Date | undefined): Date | null => {
    if (!dateString) {
      console.log(`createdAt is ${dateString === null ? 'null' : 'undefined'} for token: ${title}`);
      return null;
    }
    if (dateString instanceof Date) {
      return dateString;
    }
    const parsedDate = new Date(dateString);
    if (isNaN(parsedDate.getTime())) {
      console.log(`Invalid createdAt date for token: ${title}. Value: ${dateString}`);
      return null;
    }
    return parsedDate;
  };

  const getChipStatus = (): string | null => {
    const parsedCreatedAt = parseCreatedAt(createdAt);
    if (!parsedCreatedAt) {
      return null;
    }
    
    const now = new Date();
    const hoursOld = (now.getTime() - parsedCreatedAt.getTime()) / (1000 * 60 * 60);
    
    if (hoursOld < 12) {
      return 'NEW';
    }
    if (recentTransactions && recentTransactions > 3) {
      return 'HOT';
    }
    return null;
  };

  const getContractTypeLabel = (type: number): string => {
    switch (type) {
      case 0: return 'RESERVE RATIO';
      case 1: return 'LINEAR';
      case 2: return 'LOGARITHMIC';
      case 3: return 'EXPONENTIAL';
      default: return 'UNKNOWN';
    }
  };

  const truncateDescription = (text: string | undefined, maxLength: number) => {
    if (text === undefined || text === null || text.trim() === '') return "No description";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
  };

  const handleDescriptionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const getContractTypeTooltip = (type: number): string => {
    switch (type) {
      case 0: return "Relatively stable pricing method where price increases are predictable";
      case 1: return "A simple bonding curve with more predictability and less volatility";
      case 2: return "Bonding curve where price rises more rapidly in the beginning";
      case 3: return "Bonding curve where price increases more sharply with each purchase";
      default: return "Unknown contract type";
    }
  };

  useEffect(() => {
    const chipStatus = getChipStatus();
    const contractTypeLabel = getContractTypeLabel(contracttype);
    const parsedCreatedAt = parseCreatedAt(createdAt);
    let createdAtInfo = parsedCreatedAt ? parsedCreatedAt.toISOString() : 'Invalid Date';
    setDebugInfo(`Status: ${chipStatus}, Contract: ${contractTypeLabel}, CreatedAt: ${createdAtInfo}, Original createdAt: ${createdAt}`);
  }, [createdAt, recentTransactions, contracttype, title]);

  const chipStatus = getChipStatus();
  const contractTypeLabel = getContractTypeLabel(contracttype);
  const contractTypeTooltip = getContractTypeTooltip(contracttype);

  return (
    <CyberCard>
      <CardMedia
        component="img"
        height={isMobile ? "120" : "140"}
        image={image ? `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${image}` : `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/QmPQVWHogwehXJp6kr7EyL2k1fa2q4EsFbz7VKsDqmwViP`}
        alt={title}
        sx={{ objectFit: 'contain', paddingTop: '10px' }}
      />
      <CardContent>
        <NeonText variant={isMobile ? "h6" : "h5"} gutterBottom sx={{ wordBreak: 'break-word' }}>
          {title} ({ticker})
        </NeonText>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
          {chipStatus && (
            <CyberChip 
              label={chipStatus}
              size="small"
              chiptype="STATUS"
            />
          )}
          <Tooltip title={contractTypeTooltip} arrow>
            <CyberChip 
              label={contractTypeLabel} 
              size="small"
              chiptype="CONTRACT"
            />
          </Tooltip>
        </Box>

        <Typography 
          variant="body2" 
          color="var(--secondary-color)" 
          sx={{ 
            mb: 1, 
            cursor: 'pointer', 
            '&:hover': { textDecoration: 'underline' },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          onClick={onDescriptionClick}
        >
          {truncateDescription(description, isMobile ? 30 : 50)}
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="var(--secondary-color)">Market Cap:</Typography>
          <NeonText variant="body2">${formatNumber(tokenData.marketcap)}</NeonText>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="var(--secondary-color)">Price:</Typography>
          <NeonText variant="body2">$
            {formatPrice(tokenData.price)}
          </NeonText>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="var(--secondary-color)">Trades:</Typography>
          <NeonText variant="body2">{trades || 0}</NeonText>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
          <Typography variant="body2" color="var(--secondary-color)">Tokens Sold:</Typography>
          <CyberProgress 
            variant="determinate" 
            value={Math.min(Math.max(tokenData.percentageSold, 0), 100)} 
            sx={{ mt: 1 }}
          />
          <NeonText variant="body2" align="right">
            {formatPercentage(tokenData.percentageSold)}%
          </NeonText>
        </Box>
        
        <Typography variant="body2" color="var(--secondary-color)" sx={{ mb: 1, wordBreak: 'break-all' }}>
          Deployer: {deployer ? `${deployer.slice(0, 6)}...${deployer.slice(-4)}` : 'N/A'}
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
          {web && (
            <Tooltip title="Website">
              <a href={web} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <CyberIconButton>
                  <Language />
                </CyberIconButton>
              </a>
            </Tooltip>
          )}
          {telegram && (
            <Tooltip title="Telegram">
              <a href={telegram} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <CyberIconButton>
                  <Telegram />
                </CyberIconButton>
              </a>
            </Tooltip>
          )}
          {twitter && (
            <Tooltip title="Twitter">
              <a href={twitter} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <CyberIconButton>
                  <Twitter />
                </CyberIconButton>
              </a>
            </Tooltip>
          )}
        </Box>
      </CardContent>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{title} Description</DialogTitle>
        <DialogContent>
          <Typography>{description || "No description available"}</Typography>
          <Typography variant="caption" color="textSecondary">
            Debug Info: {debugInfo}
          </Typography>
        </DialogContent>
      </Dialog>
    </CyberCard>
  );
};

export default MainCard;