import React, { useEffect, useRef } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/logo.png';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { AppBar, Box, Button, Toolbar, Typography, styled, IconButton, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { socket } from "../../utils/socket";
import { useNavigate } from "react-router-dom";

const CyberAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1C2132, #2a3a5a)',
  borderBottom: '2px solid var(--primary-color)',
  boxShadow: '0 0 10px var(--primary-color)',
}));

const CyberButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, var(--primary-color), var(--secondary-color))',
    border: 'none',
    borderRadius: '5px',
    color: 'var(--background-color)',
    fontFamily: 'Orbitron, sans-serif',
    fontWeight: 'bold',
    padding: '6px 12px',
    textTransform: 'uppercase',
    transition: 'all 0.3s ease',
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    minWidth: 'auto',
    '&:hover': {
      background: 'linear-gradient(45deg, var(--secondary-color), var(--primary-color))',
      boxShadow: '0 0 10px var(--primary-color)',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
      padding: '4px 8px',
    },
  }));

const ScrollingBox = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  WebkitOverflowScrolling: 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  maxWidth: '100%',
  position: 'relative',
});

const TokenLink = styled('a')({
  marginRight: '1rem',
  background: '#ffffff29',
  padding: '0.25rem',
  borderRadius: '2rem',
  textDecoration: 'none',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  flexShrink: 0,
});

const NeonText = styled(Typography)(({ theme }) => ({
  color: 'var(--primary-color)',
  textShadow: '0 0 5px var(--primary-color), 0 0 10px var(--primary-color)',
  fontFamily: 'Orbitron, sans-serif',
  marginRight: '0.5rem',
  fontSize: '0.8rem',
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
  },
}));

const TokenImage = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
});

const IMAGE_BASE_URL = "https://lime-capitalist-mole-212.mypinata.cloud/ipfs/";
const fallbackImageUrl = "/api/placeholder/24/24";

interface HeaderProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

function Header({ drawerWidth, handleDrawerToggle }: HeaderProps) {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const [latestTokens, setLatestTokens] = React.useState<any[]>([]);
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);
    const ownerAddress = "0xf3663B50babCe3B54b26743498b20079911F406f"; //owner wallet
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getLatestTrade = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}`);
            console.log("Latest trades:", response.data);
            setLatestTokens(response.data);
        } catch (error) {
            console.error("Error fetching latest trades:", error);
        }
    };

    useEffect(() => {
        getLatestTrade();
        socket.on("message", getLatestTrade);
        return () => {
            socket.off("message", getLatestTrade);
        };
    }, []);

    useEffect(() => {
        const scrollInterval = setInterval(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollLeft += 1;
                if (scrollRef.current.scrollLeft >= scrollRef.current.scrollWidth / 2) {
                    scrollRef.current.scrollLeft = 0;
                }
            }
        }, 50);

        return () => clearInterval(scrollInterval);
    }, [latestTokens]);

    return (
        <CyberAppBar
            position="fixed"
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", flexWrap: 'wrap', px: { xs: 1, sm: 2 }, height: { xs: 'auto', sm: '64px' } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 1, display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {!isMobile && (
                    <ScrollingBox ref={scrollRef} sx={{ flexGrow: 1, mr: 2 }}>
                      {latestTokens?.map((item: any, index: number) => 
                          <TokenLink key={index} href={`${item.network}/${item.tokenAddress}`}>
                              <NeonText>{`${item.type} ${item.ethAmount} of ${item.tokenName}`}</NeonText>
                              <TokenImage src={`${IMAGE_BASE_URL}${item.tokenImage}`} alt={item.tokenName} />
                          </TokenLink>
                      )}
                    </ScrollingBox>
                  )}
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <CyberButton onClick={() => open()} sx={{ mr: 1 }}>
                      {isConnected ? `${address?.slice(0, 3)}...${address?.slice(-2)}` : "Connect"}
                    </CyberButton>
                    {(address?.toLowerCase() === ownerAddress?.toLowerCase()) && (
                      <CyberButton 
                        onClick={() => navigate('/admin')} 
                        sx={{ 
                          backgroundImage: "linear-gradient(90deg, #6928d5 14.14%, #9E1F63 100%)"
                        }}
                      >
                        Admin
                      </CyberButton>
                    )}
                  </Box>
                </Box>
                {isMobile && (
                  <ScrollingBox ref={scrollRef} sx={{ width: '100%', order: 2, my: 1 }}>
                    {latestTokens?.map((item: any, index: number) => 
                        <TokenLink key={index} href={`${item.network}/${item.tokenAddress}`}>
                            <NeonText>{`${item.type} ${item.ethAmount} of ${item.tokenName}`}</NeonText>
                            <TokenImage src={`${IMAGE_BASE_URL}${item.tokenImage}`} alt={item.tokenName} />
                        </TokenLink>
                    )}
                  </ScrollingBox>
                )}
            </Toolbar>
        </CyberAppBar>
    );
}

export default Header;