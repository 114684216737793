// contractUtils.ts
import { AVAX_LP_CONTRACT_ADDRESS, ARBITRUM_LP_CONTRACT_ADDRESS, BASE_LP_CONTRACT_ADDRESS, SEPOLIA_LP_CONTRACT_ADDRESS, ETHEREUM_LP_CONTRACT_ADDRESS, BSC_LP_CONTRACT_ADDRESS, POLYGON_LP_CONTRACT_ADDRESS, POLYGON_LIN_CONTRACT_ADDRESS, POLYGON_LOG_CONTRACT_ADDRESS, POLYGON_EXP_CONTRACT_ADDRESS } from "../abis/FairLaunchFactoryAddress";
import fairLaunchFactoryABI from "../abis/FairLaunchFactory.json";
import linearBondingCurveABI from "../abis/linearBondingCurveABI.json";
import logarithmicBondingCurveABI from "../abis/logarithmicBondingCurveABI.json";
import exponentialBondingCurveABI from "../abis/exponentialBondingCurveABI.json";


export const getContractAddress = (pricingMethod: string): string => {
    switch(pricingMethod.toLowerCase()) {
        case 'linear':
            return POLYGON_LIN_CONTRACT_ADDRESS;
        case 'logarithmic':
            return POLYGON_LOG_CONTRACT_ADDRESS;
        case 'exponential':
            return POLYGON_EXP_CONTRACT_ADDRESS;
        default:
            return POLYGON_LP_CONTRACT_ADDRESS;
    }
};

const logger = {
    info: (message: string) => console.log(`[INFO] ${message}`),
    debug: (message: string) => console.debug(`[DEBUG] ${message}`),
    warn: (message: string) => console.warn(`[WARN] ${message}`),
    error: (message: string) => console.error(`[ERROR] ${message}`)
};

export const getContractABI = (pricingMethod: string): any => {
    let selectedABI;
    
    switch(pricingMethod) {
        case 'linear':
            selectedABI = linearBondingCurveABI;
            break;
        case 'logarithmic':
            selectedABI = logarithmicBondingCurveABI;
            break;
        case 'exponential':
            selectedABI = exponentialBondingCurveABI;
            break;
        default:
            selectedABI = fairLaunchFactoryABI; // Reserve Ratio (default)
    }
    
    logger.info(`Selected ABI for pricing method: ${pricingMethod}`);
    logger.debug(`ABI methods: ${JSON.stringify(Object.keys(selectedABI))}`);
    
    return selectedABI;
};

export const getHref = (chainName: string | undefined, url: string, hash: string = ""): string => {
    if (!chainName) return "";
    let baseUrl = "";
    switch (chainName.toLowerCase()) {
        case "ethereum":
            baseUrl = "https://etherscan.io";
            break;
        case "sepolia":
            baseUrl = "https://sepolia.etherscan.io";
            break;
        case "base":
            baseUrl = "https://basescan.org";
            break;
        case "arbitrum":
            baseUrl = "https://arbiscan.io";
            break;
        case "avax":
            baseUrl = "https://snowtrace.io";
            break;
        case "polygon":
            baseUrl = "https://polygonscan.com";
            break;
        default:
            return "";
    }
    const type = hash === "hash" ? "tx" : "address";
    return `${baseUrl}/${type}/${url}`;
};

// Add any other utility functions here