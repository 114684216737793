import NewCurvsIcon from '../assets/images/new.svg';
import WalletIcon from '../assets/images/wallet.svg';
import DeployIcon from '../assets/images/deploy.svg'

export const MainRouter = {
    main: [
        {title: "New Tokens", icon: NewCurvsIcon,  path: "/"},
        {title: "My Tokens", icon: WalletIcon, path: "/my_tokens"},
        {title: "Deploy a Token", icon: DeployIcon, path: "/create_tokens"},
    ],
}