import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { createChart, IChartApi, ISeriesApi, ColorType, Time, UTCTimestamp, CandlestickData, WhitespaceData, HistogramData } from 'lightweight-charts';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, ethers } from 'ethers';
import axios from "axios";
import { getContractAddress, getContractABI, getHref } from "../utils/contractUtils";
import BigNumber from 'bignumber.js';
import erc20ABI from "../abis/erc20ABI.json";
import { 
  Box, Button, TextField, Typography, TypographyProps, Dialog, DialogTitle, 
  DialogContent, DialogActions, Card, CardContent, Tabs, Tab, Link, LinearProgress, CircularProgress,
  Slider, ThemeProvider, createTheme, CssBaseline, useMediaQuery, Container, useTheme
} from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CustomPaginationActionsTable from "../components/table";
import { socket } from "../utils/socket";
import Chart, { ChartProps } from '../Chart';
import { color } from "@amcharts/amcharts5";

const TOKEN_DECIMALS = 18;
const MIN_PURCHASE_AMOUNT = ethers.parseEther("0.001");
const TOTAL_SUPPLY = new BigNumber(10).pow(9); // 1 billion tokens
//const TOTAL_SUPPLY = ethers.parseUnits("1000000000", 18);
const LAUNCH_THRESHOLD = new BigNumber(5); // 5 MATIC

interface TokenDetails {
  tokenAddress: string;
  tokenName: string;
  tokenSymbol: string;
  tokenImage: string;
  marketcap: string;
  network: string;
  creatorAddress: string;
  ethReserve: string;
  tokenReserve: string;
  tokenPrice: string;
  percentageSold: string;
  trades: string;
  tokensRemaining: string;
  isLaunched: boolean;
  targetMarketCap: string;
  contracttype: number;
}

interface TradeData {
  id: number;
  tokenName: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenImage: string;
  date: string | number;
  type: "BUY" | "SELL";
  ethAmount: number | null;
  tokenAmount: string;
  txHash: string;
  swapperAddress: string;
  tokenPrice: string;
  network: string;
}

interface ChartDataPoint {
  date: number;
  tokenPrice: string;
  volume: string;
}

const toBigNumber = (value: string | number) => new BigNumber(value);
const formatForDisplay = (value: BigNumber, decimals: number = 6) => value.toFixed(decimals);
const toMatic = (wei: BigNumber): BigNumber => wei.dividedBy(new BigNumber(10).pow(18));
const toWei = (matic: BigNumber): BigNumber => matic.times(new BigNumber(10).pow(18));
const toTokenAmount = (amount: BigNumber): BigNumber => amount.dividedBy(new BigNumber(10).pow(TOKEN_DECIMALS));
const toTokenWei = (amount: BigNumber): BigNumber => amount.times(new BigNumber(10).pow(TOKEN_DECIMALS));

// Styled components (update to use theme)
const CyberCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(45deg, var(--card-background), #2a2a2a)',
  border: '2px solid var(--primary-color)',
  borderRadius: '10px',
  boxShadow: '0 0 10px var(--primary-color)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 0 20px var(--primary-color)',
  },
}));

const NeonText = styled(Typography)<TypographyProps>(() => ({
  color: 'var(--primary-color)',
  textShadow: '0 0 5px var(--primary-color), 0 0 10px var(--primary-color)',
  fontFamily: "'Roboto Mono', monospace",
}));

const CyberButton = styled(Button)(() => ({
  background: 'linear-gradient(45deg, var(--primary-color), var(--secondary-color))',
  border: 'none',
  borderRadius: '5px',
  color: 'var(--background-color)',
  fontFamily: "'Roboto Mono', monospace",
  fontWeight: 'bold',
  padding: '10px 20px',
  textTransform: 'uppercase',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, var(--secondary-color), var(--primary-color))',
    boxShadow: '0 0 10px var(--primary-color)',
  },
}));

const CyberTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--primary-color)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--secondary-color)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--accent-color)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'var(--text-color)',
  },
  '& .MuiInputBase-input': {
    color: 'var(--text-color)',
  },
  '& .MuiFormHelperText-root': {
    color: 'white', // This line changes the helperText color to white
  },
}));

const CyberLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: 'var(--card-background)',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    background: 'linear-gradient(90deg, var(--primary-color), var(--secondary-color))',
  },
}));

const CyberSlider = styled(Slider)(({ theme }) => ({
  color: 'var(--primary-color)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: 'var(--secondary-color)',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'var(--accent-color)',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    color: 'black',
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'var(--primary-color)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'var(--text-color)',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: '0.75rem',
    color: 'var(--text-color)',
  },
}));

const getPricingMethodString = (contractType: number): string => {
  switch (contractType) {
    case 0:
      return ''; // Reserve Ratio (default)
    case 1:
      return 'linear';
    case 2:
      return 'logarithmic';
    case 3:
      return 'exponential';
    default:
      console.warn(`Unknown contract type: ${contractType}, defaulting to Reserve Ratio`);
      return '';
  }
};

const DetailPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tokenData, setTokenData] = useState<TokenDetails | null>(null);
  const [chartData, setChartData] = useState<ChartProps['data']>({ price: [], volume: [] });
  const [tradeData, setTradeData] = useState<TradeData[]>([]);
  const [chatData, setChatData] = useState<any[]>([]);
  const [holders, setHolders] = useState<any[]>([]);
  const [isLiveUpdating, setIsLiveUpdating] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [amount, setAmount] = useState<string>('');
  const [type, setType] = useState<"BUY" | "SELL">("BUY");
  const [calculatedCost, setCalculatedCost] = useState<string>('0');
  const [userTokenBalance, setUserTokenBalance] = useState<BigNumber>(new BigNumber(0));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState<{
    tokenAmount: string;
    maticCost: string;
  } | null>(null);
  const [transactionResult, setTransactionResult] = useState<string | null>(null);
  const [transactionError, setTransactionError] = useState<string | null>(null);
  const [launched, setLaunched] = useState(false);
  const [currentMarketCap, setCurrentMarketCap] = useState<BigNumber>(new BigNumber(0));
  const [percentageSold, setPercentageSold] = useState<number>(0);
  const [tokensRemaining, setTokensRemaining] = useState<BigNumber>(TOTAL_SUPPLY);
  const [isLaunchStatusLoading, setIsLaunchStatusLoading] = useState(true);
  const [tokenAmountError, setTokenAmountError] = useState<string | null>(null);
  const [showBuyRemaining, setShowBuyRemaining] = useState(false);
  const [slippage, setSlippage] = useState<number>(0);

  const { chain, id } = useParams<{ chain: string; id: string }>();
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candlestickSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);

  //const theme = useTheme();
  const theme = createTheme({
    typography: {
      fontFamily: "'Roboto Mono', monospace",
    },
    palette: {
      mode: 'dark',
      background: {
        default: '#131313',
        paper: '#252525',
      },
      text: {
        primary: '#ffffff',
        secondary: 'rgba(255, 255, 255, 0.7)',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');
          body {
            background-color: #131313;
          }
        `,
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (max-width: 600px)': {
              padding: '0 8px',
            },
          },
        },
      },
      MuiGrid2: {
        styleOverrides: {
          root: {
            '@media (max-width: 600px)': {
              padding: '4px',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: "'Roboto Mono', monospace",
            color: '#ffffff',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "'Roboto Mono', monospace",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: '#252525',
            color: '#ffffff',
          },
        },
      },
    },
  });

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchTokenData = async (tokenAddress: string, network: string, contractType: number): Promise<TokenDetails> => {
    const provider = new ethers.JsonRpcProvider(getRpcUrl(network));
    const pricingMethod = getPricingMethodString(contractType);
    const contractABI = getContractABI(pricingMethod);
    const contractAddress = getContractAddress(pricingMethod);
    const contract = new ethers.Contract(contractAddress, contractABI, provider);
  
    try {
      const totalSupplyWei = BigInt(ethers.parseUnits(TOTAL_SUPPLY.toString(), 18).toString());
      const totalSupplyEther = TOTAL_SUPPLY;
  
      let tokensSold: BigNumber;
      let price: BigNumber;
      let marketcap: BigNumber;
      let tokenReserve: BigNumber;
      let trades: number;
  
      const priceWei = await contract.getPrice(tokenAddress);
      price = new BigNumber(ethers.formatEther(priceWei));
  
      // If price is zero or very close to zero, set it to the initial price
      if (price.isLessThan('0.0000000001')) {
        price = new BigNumber('0.0000000001');
      }
  
      if (contractType === 0) { // Reserve Ratio
        const tokenReserveWei = await contract.getTokenReserve(tokenAddress);
        const adjustedTokenReserveWei = BigInt(tokenReserveWei) > totalSupplyWei ? totalSupplyWei : BigInt(tokenReserveWei);
        tokenReserve = new BigNumber(ethers.formatEther(adjustedTokenReserveWei.toString()));
        tokensSold = totalSupplyEther.minus(tokenReserve);
        trades = Number(await contract.getTokenTrades(tokenAddress));
      } else {
        // For Linear, Logarithmic, and Exponential
        const [soldTokens, initialTokenReserve, currentTokenReserve, calculatedTokensSold] = await contract.getTokensSold(tokenAddress);
        tokensSold = new BigNumber(ethers.formatEther(soldTokens));
        tokenReserve = new BigNumber(ethers.formatEther(currentTokenReserve));
        trades = Number(await contract.getTokenTrades(tokenAddress));
      }
  
      marketcap = price.times(totalSupplyEther);
      const percentageSold = tokensSold.div(totalSupplyEther).times(100);
      const tokensRemaining = totalSupplyEther.minus(tokensSold);
  
      const targetMarketCapWei = await contract.TARGET_MARKET_CAP();
      const targetMarketCap = new BigNumber(ethers.formatEther(targetMarketCapWei));
      const isLaunched = marketcap.isGreaterThanOrEqualTo(LAUNCH_THRESHOLD);
  
      return {
        tokenAddress,
        tokenName: "", // Fetch this from your API
        tokenSymbol: "", // Fetch this from your API
        tokenImage: "", // Fetch this from your API
        marketcap: marketcap.toString(),
        network,
        creatorAddress: "", // Fetch this from your API
        ethReserve: ethers.formatEther(await provider.getBalance(contractAddress)),
        tokenReserve: tokenReserve.toString(),
        tokenPrice: price.toString(),
        percentageSold: percentageSold.toString(),
        trades: trades.toString(),
        tokensRemaining: tokensRemaining.toString(),
        isLaunched: isLaunched,
        targetMarketCap: targetMarketCap.toString(),
        contracttype: contractType
      };
    } catch (error) {
      console.error("Error fetching token data:", error, "for token:", tokenAddress);
      throw error;
    }
  };

  const setupLiveUpdates = useCallback(() => {
    if (!chain || !id) return;

    socket.on('connect', () => {
      console.log('WebSocket connected');
      socket.emit('subscribe', { chain, tokenId: id });
    });

    socket.on('tokenUpdate', (updatedData) => {
      setTokenData(prevData => ({
        ...prevData,
        ...updatedData
      } as TokenDetails));
      fetchPriceAndStatus(updatedData);
    });

    socket.on('chartUpdate', (newDataPoint: ChartDataPoint) => {
      const newPrice: CandlestickData<Time> = {
        time: newDataPoint.date as Time,
        open: parseFloat(newDataPoint.tokenPrice),
        high: parseFloat(newDataPoint.tokenPrice),
        low: parseFloat(newDataPoint.tokenPrice),
        close: parseFloat(newDataPoint.tokenPrice),
      };
      
      const newVolume: HistogramData<Time> = {
        time: newDataPoint.date as Time,
        value: parseFloat(newDataPoint.volume),
        color: '#26a69a', // Default to green, you might want to adjust this based on price movement
      };

      setChartData(prevData => ({
        price: [...prevData.price, newPrice],
        volume: [...prevData.volume, newVolume],
      }));

      // Update tokenData with the new price
      setTokenData(prevData => {
        if (prevData === null) {
          return null;
        }
        return {
          ...prevData,
          tokenPrice: newDataPoint.tokenPrice
        };
      });
    });

    setIsLiveUpdating(true);

    return () => {
      socket.off('tokenUpdate');
      socket.off('tradeUpdate');
      socket.off('chartUpdate');
      socket.emit('unsubscribe', { chain, tokenId: id });
    };
  }, [chain, id]);

  const fetchPriceAndStatus = useCallback(async (tokenData: TokenDetails) => {
    try {
      const updatedData = await fetchTokenData(tokenData.tokenAddress, tokenData.network, tokenData.contracttype);
      
      setTokenData(prevData => {
        if (prevData === null) return updatedData;
        // Only update if there are actual changes
        if (JSON.stringify(prevData) !== JSON.stringify(updatedData)) {
          return {
            ...prevData,
            ...updatedData,
            tokenName: prevData.tokenName,
            tokenSymbol: prevData.tokenSymbol,
            tokenImage: prevData.tokenImage,
            creatorAddress: prevData.creatorAddress
          };
        }
        return prevData;
      });
  
      setCurrentMarketCap(new BigNumber(updatedData.marketcap));
      setLaunched(updatedData.isLaunched);
      setTokensRemaining(new BigNumber(updatedData.tokensRemaining));
      setPercentageSold(parseFloat(updatedData.percentageSold));
    } catch (error) {
      console.error("Error fetching price and status:", error);
    } finally {
      setIsLaunchStatusLoading(false);
    }
  }, []);
  
  // Modify the useEffect for fetching price and status
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (tokenData && isLaunchStatusLoading) {
        await fetchPriceAndStatus(tokenData);
        if (isMounted) setIsLaunchStatusLoading(false);
      }
    };
    fetchData();
    return () => { isMounted = false; };
  }, [fetchPriceAndStatus, tokenData, isLaunchStatusLoading]);
  
  // Add this useEffect to periodically update the data
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tokenData) {
        setIsLaunchStatusLoading(true);
      }
    }, 30000); // Update every 30 seconds
  
    return () => clearInterval(intervalId);
  }, [tokenData]);
  
  const fetchInitialData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!chain || !id) {
        throw new Error("Missing chain or token ID in URL");
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}/tokens/${chain}/${id}`;
      const response = await axios.get(apiUrl);
      
      console.log("API Response:", response.data); // Log the entire response
  
      if (response.data && (response.data.tokenDetails || response.data.tokenDetils)) {
        const initialTokenData = response.data.tokenDetails || response.data.tokenDetils;
        console.log("Initial Token Data:", initialTokenData); // Log the initial token data
  
        if (!initialTokenData.tokenAddress || !initialTokenData.network) {
          throw new Error("Incomplete token data received from the server");
        }
        
        setTokenData(initialTokenData);
        
        // Process chart data
        const processChartData = (rawData: ChartDataPoint[]): ChartProps['data'] => {
          console.log("Raw Chart Data:", rawData); // Log the raw chart data
          const priceData: CandlestickData<Time>[] = [];
          const volumeData: HistogramData<Time>[] = [];
          const aggregatedData: { [key: number]: CandlestickData<Time> & { volume: number, count: number } } = {};
        
          const INTERVAL = 5 * 60; // 5 minutes in seconds
          const EMBELLISH_FACTOR = 0.01; // 0.01% variation for single-transaction candles
        
          rawData.forEach((item) => {
            const timestamp = Math.floor(item.date / INTERVAL) * INTERVAL;
            const price = parseFloat(item.tokenPrice);
            const volume = parseFloat(item.volume);
        
            if (!aggregatedData[timestamp]) {
              aggregatedData[timestamp] = {
                time: timestamp as Time,
                open: price,
                high: price,
                low: price,
                close: price,
                volume: volume,
                count: 1
              };
            } else {
              const candle = aggregatedData[timestamp];
              candle.high = Math.max(candle.high, price);
              candle.low = Math.min(candle.low, price);
              candle.close = price;
              candle.volume += volume;
              candle.count += 1;
            }
          });
        
          Object.values(aggregatedData).forEach((candle) => {
            if (candle.count === 1) {
              // Embellish single-transaction candles
              const variation = candle.open * EMBELLISH_FACTOR;
              candle.high = candle.open + variation;
              candle.low = candle.open - variation;
              // Slightly offset the close price to create a small body
              candle.close = candle.open + (variation / 2);
            }
        
            priceData.push({
              time: candle.time,
              open: candle.open,
              high: candle.high,
              low: candle.low,
              close: candle.close
            });
        
            volumeData.push({
              time: candle.time,
              value: candle.volume,
              color: candle.close >= candle.open ? '#26a69a' : '#ef5350'
            });
          });
        
          return { price: priceData, volume: volumeData };
        };
  
        const processedChartData = processChartData(response.data.chartList || []);
        console.log("Processed Chart Data:", processedChartData); // Log the processed chart data
        setChartData(processedChartData);
  
        setChatData(response.data.chatList || []);
        setHolders(response.data.holders || []);
  
        // Fetch additional data
        await fetchPriceAndStatus(initialTokenData);
      } else {
        throw new Error("Invalid data structure received from the server");
      }
    } catch (error) {
      console.error("Error fetching token details:", error);
      setError(error instanceof Error ? error.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [chain, id, fetchPriceAndStatus]);

  useEffect(() => {
    fetchInitialData();
    const cleanup = setupLiveUpdates();
    return () => {
      if (cleanup) cleanup();
    };
  }, [fetchInitialData, setupLiveUpdates]);

  useEffect(() => {
    updateUserTokenBalance();
  }, [address, walletProvider, tokenData]);

  const updateUserTokenBalance = async () => {
    if (tokenData && address && walletProvider) {
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const tokenContract = new ethers.Contract(tokenData.tokenAddress, erc20ABI, signer);
        const balanceWei = await tokenContract.balanceOf(address);
        const balanceInTokens = toTokenAmount(new BigNumber(balanceWei.toString()));
        setUserTokenBalance(balanceInTokens);
      } catch (error) {
        console.error("Error updating user token balance:", error);
      }
    }
  };

  const handleAmountChange = (inputValue: string) => {
    if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
      setAmount(inputValue);
      if (tokenData?.tokenPrice) {
        const tokenAmount = toBigNumber(inputValue || '0');
        const tokenPriceInMatic = toBigNumber(tokenData.tokenPrice);
        const calculatedCostMatic = type === "BUY" 
          ? tokenAmount.times(tokenPriceInMatic)
          : tokenAmount.times(tokenPriceInMatic);
        setCalculatedCost(calculatedCostMatic.toString());
        setConfirmationDetails({
          tokenAmount: tokenAmount.toString(),
          maticCost: calculatedCostMatic.toString()
        });

        // Add check for available tokens
        if (type === "BUY" && tokenAmount.isGreaterThan(tokensRemaining)) {
          setTokenAmountError("Requested amount exceeds available tokens.");
        } else {
          setTokenAmountError(null);
        }
      }
    }
  };

  useEffect(() => {
    const remainingPercentage = tokensRemaining.div(TOTAL_SUPPLY).times(100).toNumber();
    setShowBuyRemaining(remainingPercentage < 25);
  }, [tokensRemaining]);

  const slippageValues = [0, 0.001, 0.005, 0.01, 0.02, 0.05];

  const handleSlippageChange = (event: Event, newValue: number | number[]) => {
    setSlippage(slippageValues[newValue as number]);
  };

  const handleBuySell = async () => {
    setTransactionError(null);
    if (!isConnected || !tokenData) {
      setTransactionError("Please connect your wallet and try again.");
      await open();
      return;
    }
    if (chainId !== getChainId(tokenData.network)) {
      setTransactionError(`Please switch to the ${tokenData.network} network and try again.`);
      return;
    }
    const tokenAmount = toBigNumber(amount || '0');
    const tokenPriceInMatic = toBigNumber(tokenData.tokenPrice);
    let maticCost: BigNumber;
    let adjustedAmount: BigNumber;
    if (type === "BUY") {
      maticCost = tokenAmount.times(tokenPriceInMatic);
      adjustedAmount = maticCost.times(1.03).times(1 + slippage); // Add slippage to buy
    } else {
      maticCost = tokenAmount.times(tokenPriceInMatic);
      adjustedAmount = tokenAmount.times(0.97).times(1 - slippage); // Subtract slippage from sell
      if (adjustedAmount.isGreaterThan(userTokenBalance)) {
        setTransactionError("Insufficient token balance for this sale.");
        return;
      }
    }
    setConfirmationDetails({
      tokenAmount: type === "BUY" ? tokenAmount.toString() : adjustedAmount.toString(),
      maticCost: type === "BUY" ? adjustedAmount.toString() : maticCost.toString()
    });
    setShowConfirmation(true);
  };

  const updateTradeData = useCallback((newTrade: TradeData) => {
    setTradeData(prevTrades => {
      const updatedTrades = [newTrade, ...prevTrades];
      return updatedTrades.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
    });
  }, []);

  const handleConfirmedTransaction = async () => {
    setShowConfirmation(false);
    setTransactionError(null);
    setTransactionResult(null);
    if (!walletProvider || !address || !tokenData || !confirmationDetails) {
      setTransactionError("Missing required information. Please try again.");
      return;
    }
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const contractAddress = getContractAddress(tokenData.network);
      const contract = new Contract(contractAddress, getContractABI(tokenData.network), signer);
      let transaction;
      if (type === "BUY") {
        transaction = await contract.buyTokens(tokenData.tokenAddress, {
          value: toWei(toBigNumber(confirmationDetails.maticCost)).toString()
        });
      } else {
        const tokenContract = new Contract(tokenData.tokenAddress, erc20ABI, signer);
        const approveTx = await tokenContract.approve(contractAddress, toTokenWei(toBigNumber(confirmationDetails.tokenAmount)).toString());
        await approveTx.wait();
        transaction = await contract.sellTokens(tokenData.tokenAddress, toTokenWei(toBigNumber(confirmationDetails.tokenAmount)).toString());
      }
      const receipt = await transaction.wait();
      const newTrade = await logTransaction(
        receipt.transactionHash,
        confirmationDetails.maticCost,
        confirmationDetails.tokenAmount
      );
      updateTradeData(newTrade);
      setTransactionResult(`${type} transaction successful!`);
      updateUserTokenBalance();
      fetchPriceAndStatus(tokenData);
    } catch (error) {
      console.error("Transaction failed:", error);
      setTransactionError(error instanceof Error ? error.message : "Transaction failed. Please try again.");
    }
  };

  const getTrades = async () => {
    const body = {
      tokenAddress: id,
      network: chain
    }
    try {
      const response = await axios.post<TradeData[]>(`${process.env.REACT_APP_API_URL}/trades`, body);
      console.log("Raw trade data (first item):", response.data[0]); // Log the first trade item
      const sortedTradeData = response.data.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setTradeData(sortedTradeData);
    } catch (error) {
      console.error("Error fetching trade data:", error);
    }
  };

const logTransaction = async (txHash: string, maticAmount: string, tokenAmount: string): Promise<TradeData> => {
  const newTrade: TradeData = {
    id: Date.now(), // Use timestamp as a temporary id
    tokenName: tokenData?.tokenName || "",
    tokenSymbol: tokenData?.tokenSymbol || "",
    tokenAddress: tokenData?.tokenAddress || "",
    tokenImage: tokenData?.tokenImage || "",
    swapperAddress: address || "",
    type: type,
    ethAmount: maticAmount ? parseFloat(maticAmount) : null,
    tokenAmount: tokenAmount,
    network: chain || "",
    txHash: txHash,
    tokenPrice: tokenData?.tokenPrice || "",
    date: new Date().toISOString()
  };
  
  const body = {
    type: "swap",
    data: newTrade
  };
  socket.emit("message", JSON.stringify(body));
  console.log("Transaction logged via socket:", body);
  return newTrade;
};

// Add this useEffect to listen for trade updates from other users
useEffect(() => {
  socket.on('tradeUpdate', (newTrade: TradeData) => {
    updateTradeData(newTrade);
  });

  return () => {
    socket.off('tradeUpdate');
  };
}, [updateTradeData]);

const formatLargeNumber = (num: BigNumber | null | undefined): string => {
  if (num == null) return 'N/A';
  if (num.isGreaterThanOrEqualTo(1e9)) return num.div(1e9).toFixed(2) + 'B';
  if (num.isGreaterThanOrEqualTo(1e6)) return num.div(1e6).toFixed(2) + 'M';
  if (num.isGreaterThanOrEqualTo(1e3)) return num.div(1e3).toFixed(2) + 'K';
  return num.toFixed(2);
};

const formatPriceForDisplay = (price: string | null): { 
  integerPart: string, 
  zeroCount: number, 
  significantDecimalPart: string 
} => {
  if (price === null) return { integerPart: "N/A", zeroCount: 0, significantDecimalPart: "" };
  const priceNumber = new BigNumber(price);
  if (priceNumber.isEqualTo('0.0000000001')) {
    return { integerPart: "0", zeroCount: 8, significantDecimalPart: "01" };
  }
  const [integerPart, decimalPart = ""] = price.split('.');
  const leadingZeros = decimalPart.match(/^0*/)?.[0].length ?? 0;
  const significantDecimalPart = decimalPart.slice(leadingZeros);
  return {
    integerPart: integerPart === "0" ? "0" : integerPart,
    zeroCount: leadingZeros,
    significantDecimalPart: significantDecimalPart || "0"
  };
};

  const getRpcUrl = (network: string): string => {
    const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
    if (!infuraProjectId) {
      console.error("Infura Project ID is not set");
      return "";
    }
    switch(network) {
      case "Polygon":
        return `https://polygon-mainnet.infura.io/v3/${infuraProjectId}`;
      default:
        console.error("Unsupported network:", network);
        return "";
    }
  };

  const getChainId = (network: string): number => {
    return 137; // Always return 137 for Polygon mainnet
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [comment, setComment] = useState("");

  const handleOpenCommentDialog = () => {
    setOpenCommentDialog(true);
  };

  const handleCloseCommentDialog = () => {
    setOpenCommentDialog(false);
  };

  const handlePostComment = async () => {
    if (!id || !address || !comment || !chain) return;
    const body = {
      tokenAddress: id,
      replyAddress: address,
      comment: comment,
      network: chain
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chats/reply`, body);
      setChatData(response.data);
    } catch (error) {
      console.error("Error posting comment:", error);
    }
    setOpenCommentDialog(false);
    setComment("");
  };

  React.useEffect(() => {
    getTrades();
}, []);

useEffect(() => {
  const remainingPercentage = tokensRemaining.div(TOTAL_SUPPLY).times(100).toNumber();
  setShowBuyRemaining(remainingPercentage < 25);
}, [tokensRemaining]);

const handleBuyRemaining = () => {
  const remainingAmount = tokensRemaining.toString();
  setAmount(remainingAmount);
  handleAmountChange(remainingAmount);
};

const slippageMarks = slippageValues.map((value, index) => ({
  value: index,
  label: `${(value * 100).toFixed(1)}%`,
}));

const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const dateFormat = (date: string | number): string => {
  let dateObj: Date;
  const currentDate = new Date();
  
  if (typeof date === 'string') {
      dateObj = new Date(date);
  } else if (typeof date === 'number') {
      // Convert seconds to milliseconds
      dateObj = new Date(date * 1000);
      
      // If the date is more than a day in the future, assume it's an error and use the current date
      if (dateObj.getTime() - currentDate.getTime() > 24 * 60 * 60 * 1000) {
          console.warn(`Future date detected: ${dateObj.toISOString()}. Using current date instead.`);
          dateObj = currentDate;
      }
  } else {
      return 'Invalid date';
  }
  
  if (isNaN(dateObj.getTime())) {
      return 'Invalid date';
  }
  
  return dateObj.toLocaleString();
};

if (isLoading) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
}

if (error) {
  return (
    <Box sx={{ p: 3 }}>
      <Typography color="error" variant="h6">Error: {error}</Typography>
    </Box>
  );
}

if (!tokenData) {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6">No data available</Typography>
    </Box>
  );
}

return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container 
      maxWidth="lg" 
      sx={{ 
        overflowX: 'hidden', 
        px: { xs: 1, sm: 2 }, 
        pb: { xs: 4, sm: 6 }
      }}
    >
      <div className="animated-bg"></div>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ p: 3 }}>
          <Typography color="error" variant="h6">Error: {error}</Typography>
        </Box>
      ) : tokenData ? (
        <Grid2 container spacing={{ xs: 1, sm: 2 }} mb={2} sx={{ mt: '6px' }}>
          <Grid2 xs={12} md={8}>
            <CyberCard>
              <CardContent sx={{ px: { xs: 2, sm: 3 } }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  mb: 2 
                }}>
                  <Link
                    href={`https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${tokenData.tokenImage}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'block',
                      width: { xs: '60px', sm: '80px' },
                      height: { xs: '60px', sm: '80px' },
                      marginBottom: 2,
                      borderRadius: '50%',
                      overflow: 'hidden',
                    }}
                  >
                    <img 
                      src={tokenData.tokenImage 
                        ? `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/${tokenData.tokenImage}`
                        : `https://lime-capitalist-mole-212.mypinata.cloud/ipfs/QmPQVWHogwehXJp6kr7EyL2k1fa2q4EsFbz7VKsDqmwViP`
                      }
                      alt={`${tokenData.tokenName} logo`} 
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    />
                  </Link>
                  <Box sx={{ textAlign: 'center', width: '100%' }}>
                  <NeonText 
  variant={isSmallScreen ? "h6" : "h5"} 
  component="div" 
  sx={{ 
    display: 'flex', 
    alignItems: 'baseline', 
    justifyContent: 'center',
    flexWrap: 'wrap'
  }}
>
  {tokenData.tokenPrice ? (
    <>
      {(() => {
        const price = new BigNumber(tokenData.tokenPrice);
        if (price.isEqualTo('0.0000000001')) {
          return (
            <>
              0.
              <span style={{ fontSize: '0.8em', opacity: 0.7 }}>{'0'.repeat(8)}</span>
              <span style={{ color: 'var(--accent-color)' }}>01</span>
            </>
          );
        } else {
          const { integerPart, zeroCount, significantDecimalPart } = formatPriceForDisplay(tokenData.tokenPrice);
          return (
            <>
              {integerPart}
              {(zeroCount > 0 || significantDecimalPart !== "0") && '.'}
              {zeroCount > 0 && (
                <span style={{ fontSize: '0.8em', opacity: 0.7 }}>{'0'.repeat(zeroCount)}</span>
              )}
              <span style={{ color: 'var(--accent-color)' }}>{significantDecimalPart}</span>
            </>
          );
        }
      })()}
      <Typography component="span" sx={{ ml: 1, fontSize: '0.7em', fontWeight: 'normal' }}>MATIC</Typography>
    </>
  ) : (
    <Typography color="text.secondary">Price unavailable</Typography>
  )}
  {isLiveUpdating && (
    <Typography component="span" sx={{ ml: 2, fontSize: '0.8em', color: 'var(--accent-color)' }}>
      ● Live
    </Typography>
  )}
</NeonText>
                  </Box>
                </Box>

                <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', wordBreak: 'break-all' }}>
                  Token Address:{' '}
                  <Link
                    href={getHref(tokenData.network, tokenData.tokenAddress)}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'var(--accent-color)',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'var(--secondary-color)',
                      },
                    }}
                  >
                    {tokenData.tokenAddress}
                  </Link>
                </Typography>

                <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                  Launch Status:{' '}
                  {isLaunchStatusLoading ? (
                    <Typography component="span" color="text.secondary">Loading...</Typography>
                  ) : tokenData.isLaunched ? (
                    <Typography component="span" color="var(--accent-color)">Launched</Typography>
                  ) : (
                    <Typography component="span" color="var(--secondary-color)">Pre-launch</Typography>
                  )}
                </Typography>

                {!isLaunchStatusLoading && !tokenData.isLaunched && (
                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography variant="body2" gutterBottom>Progress to launch:</Typography>
                    <CyberLinearProgress 
                      variant="determinate" 
                      value={Math.min(currentMarketCap.div(LAUNCH_THRESHOLD).times(100).toNumber(), 100)} 
                    />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Current Market Cap: {formatLargeNumber(currentMarketCap)} MATIC
                    </Typography>
                  </Box>
                )}

                <Chart data={chartData} latestPrice={tokenData.tokenPrice} />
                {(chartData.price.length === 0 || chartData.volume.length === 0) && (
                  <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                    No chart data available. The chart will appear when price data is available.
                  </Typography>
                )}
              </CardContent>
            </CyberCard>

          <CyberCard sx={{ mt: 2 }}>
            <CardContent sx={{ px: { xs: 2, sm: 3 } }}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                sx={{ mb: 2 }}
                variant="fullWidth"
              >
                <Tab label="Comments" value="1" sx={{ color: 'var(--text-color)' }} />
                <Tab label="Trades" value="2" sx={{ color: 'var(--text-color)' }} />
              </Tabs>

              {tabValue === '1' && (
                <Box>
                  {chatData.map((item, index) => (
                    <CyberCard key={index} sx={{ mb: '0.5rem' }}>
                      <CardContent>
                        <Box display='flex' alignItems='center' flexWrap="wrap">
                          <Link href={getHref(item.network, item.replyAddress)} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'var(--accent-color)', wordBreak: 'break-all' }}>
                            {item.replyAddress.slice(0, 5)}...{item.replyAddress.slice(-4)}
                          </Link>
                          <Typography sx={{ ml: '0.5rem', color: 'var(--secondary-color)' }}>{dateFormat(item.date)}</Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ wordBreak: 'break-word' }}>{item.comment}</Typography>
                        </Box>
                      </CardContent>
                    </CyberCard>
                  ))}
                  <CyberButton onClick={handleOpenCommentDialog}>Post</CyberButton>
                </Box>
              )}

{tabValue === '2' && (
  <Box sx={{ overflowX: 'auto' }}>
    {tradeData.length > 0 ? (
      <>
        <Typography variant="body2" gutterBottom>
          Number of trades: {tradeData.length}
        </Typography>
        <CustomPaginationActionsTable 
  data={tradeData
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .map((trade) => ({
      ...trade,
      date: dateFormat(trade.date),
      maticAmount: trade.ethAmount != null 
        ? formatLargeNumber(toBigNumber(trade.ethAmount.toString()))
        : 'N/A'
    }))}
/>
      </>
    ) : (
      <Typography>No trade data available.</Typography>
    )}
  </Box>
)}
            </CardContent>
          </CyberCard>
          </Grid2>

<Grid2 xs={12} md={4}>
  <CyberCard>
    <CardContent sx={{ px: { xs: 2, sm: 3 } }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <CyberButton onClick={() => setType("BUY")} variant={type === "BUY" ? "contained" : "outlined"} fullWidth sx={{ mr: 1 }}>BUY</CyberButton>
        <CyberButton onClick={() => setType("SELL")} variant={type === "SELL" ? "contained" : "outlined"} fullWidth>SELL</CyberButton>
      </Box>

      <CyberTextField
        label={`Amount of tokens to ${type.toLowerCase()}`}
        type="text"
        value={amount}
        onChange={(e) => handleAmountChange(e.target.value)}
        fullWidth
        margin="normal"
        helperText={type === "BUY" ? `Minimum purchase: ${ethers.formatEther(MIN_PURCHASE_AMOUNT)} MATIC` : ""}
        error={!!tokenAmountError}
      />

      {showBuyRemaining && type === "BUY" && !tokenData.isLaunched && (
        <CyberButton 
          onClick={handleBuyRemaining}
          fullWidth
          sx={{ mt: 1, mb: 2 }}
        >
          Buy Remaining Tokens
        </CyberButton>
      )}

      {tokenAmountError && (
        <Typography color="error" sx={{ mt: 1 }}>
          {tokenAmountError}
        </Typography>
      )}

      <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
        Estimated {type === "BUY" ? "cost" : "receive"}: {formatForDisplay(toBigNumber(calculatedCost))} MATIC
      </Typography>

      <Box sx={{ mt: 3, mb: 2 }}>
        <Typography variant="body1" gutterBottom>
          Tokens Remaining: {tokenData.isLaunched ? '0' : formatLargeNumber(tokensRemaining)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tokens Sold: {tokenData.isLaunched ? '100.00' : percentageSold.toFixed(2)}%
        </Typography>
        <CyberLinearProgress 
          variant="determinate" 
          value={tokenData.isLaunched ? 100 : percentageSold} 
          sx={{ mt: 1 }}
        />
        {percentageSold === 0 && !tokenData.isLaunched && (
          <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
            Be the first to buy this new token!
          </Typography>
        )}
      </Box>

      <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
        Slippage Tolerance: {(slippage * 100).toFixed(1)}%
      </Typography>
      <Box sx={{ px: 1, py: 2 }}>
        <CyberSlider
          value={slippageValues.indexOf(slippage)}
          onChange={handleSlippageChange}
          step={null}
          marks={slippageMarks}
          min={0}
          max={slippageValues.length - 1}
          valueLabelDisplay="auto"
          valueLabelFormat={(index) => `${(slippageValues[index] * 100).toFixed(1)}%`}
        />
      </Box>

      <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
        Your Balance: {formatLargeNumber(userTokenBalance)} {tokenData.tokenSymbol}
      </Typography>

      <CyberButton 
        onClick={handleBuySell} 
        fullWidth 
        sx={{ mt: 2 }}
        disabled={!isConnected || tokenData.isLaunched || (type === "SELL" && userTokenBalance.isZero()) || !!tokenAmountError}
      >
        {isConnected ? `Place ${type} Order` : 'Connect Wallet'}
      </CyberButton>

      {transactionError && (
        <Typography color="error" sx={{ mt: 2 }}>
          {transactionError}
        </Typography>
      )}

      {transactionResult && (
        <NeonText variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
          {transactionResult}
        </NeonText>
      )}
    </CardContent>
  </CyberCard>
</Grid2>
</Grid2>
) : (
<Box sx={{ p: 3 }}>
<Typography variant="h6">No data available</Typography>
</Box>
)}

{/* Confirmation Dialog */}
<Dialog 
open={showConfirmation} 
onClose={() => setShowConfirmation(false)} 
fullWidth
maxWidth="sm"
PaperProps={{
style: {
  backgroundColor: 'var(--card-background)',
  color: 'var(--text-color)',
  border: '1px solid var(--primary-color)',
},
}}
>
<DialogTitle>{`Confirm ${type} Transaction`}</DialogTitle>
<DialogContent>
<Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
  You are about to {type.toLowerCase()} {formatLargeNumber(toBigNumber(confirmationDetails?.tokenAmount || "0"))} tokens
  {type === "BUY" 
    ? ` for ${formatLargeNumber(toBigNumber(confirmationDetails?.maticCost || "0"))} MATIC (including 3% fee)` 
    : `, which will result in receiving approximately ${formatLargeNumber(toBigNumber(confirmationDetails?.maticCost || "0"))} MATIC (after 3% fee)`}.
</Typography>
</DialogContent>
<DialogActions>
<CyberButton onClick={() => setShowConfirmation(false)}>Cancel</CyberButton>
<CyberButton onClick={handleConfirmedTransaction} variant="contained">Confirm</CyberButton>
</DialogActions>
</Dialog>

{/* Comment Dialog */}
<Dialog 
open={openCommentDialog} 
onClose={handleCloseCommentDialog} 
fullWidth
maxWidth="sm"
PaperProps={{
style: {
  backgroundColor: 'var(--card-background)',
  color: 'var(--text-color)',
  border: '1px solid var(--primary-color)',
},
}}
>
<DialogTitle>Add a comment</DialogTitle>
<DialogContent>
<CyberTextField 
  multiline 
  rows={4} 
  fullWidth 
  value={comment} 
  onChange={(e) => setComment(e.target.value)}
  sx={{ mt: 2 }}
/>
</DialogContent>
<DialogActions>
<CyberButton onClick={handleCloseCommentDialog}>Cancel</CyberButton>
<CyberButton onClick={handlePostComment} variant="contained">Post</CyberButton>
</DialogActions>
</Dialog>
</Container>
</ThemeProvider>
);
};

export default DetailPage;