const SEPOLIA_LP_CONTRACT_ADDRESS = "0x78d10FDA5670f8abe61682665e57213E63809A68";
const ETHEREUM_LP_CONTRACT_ADDRESS = "";
const BSC_LP_CONTRACT_ADDRESS = "";
const AVAX_LP_CONTRACT_ADDRESS = ""; 
const ARBITRUM_LP_CONTRACT_ADDRESS = ""; 
const BASE_LP_CONTRACT_ADDRESS = "";
const POLYGON_LP_CONTRACT_ADDRESS = "0x075572f8C138659826FAaf2D7b61e540dCD4C114";
const POLYGON_LIN_CONTRACT_ADDRESS = "0x19c8C74654735826b13b5476E8F67A944421FE14";
const POLYGON_LOG_CONTRACT_ADDRESS = "0x8aFf1F48220dAE400dB82698196046a65909948f"; 
const POLYGON_EXP_CONTRACT_ADDRESS = "0xC1d9b74cd5F4a5a4ea92F2ECa3F68eec43B997AE"; 
export {SEPOLIA_LP_CONTRACT_ADDRESS, BSC_LP_CONTRACT_ADDRESS, ETHEREUM_LP_CONTRACT_ADDRESS, AVAX_LP_CONTRACT_ADDRESS, ARBITRUM_LP_CONTRACT_ADDRESS, BASE_LP_CONTRACT_ADDRESS, POLYGON_LP_CONTRACT_ADDRESS, POLYGON_LIN_CONTRACT_ADDRESS, POLYGON_LOG_CONTRACT_ADDRESS, POLYGON_EXP_CONTRACT_ADDRESS};