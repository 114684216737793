import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/layout';
import DashboardPage from './pages/dashboard';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { MainContextProvider } from './context/mainContext';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import walletConnectLogo from "./assets/images/wallet-connect.jpg";
import coinbaseLogo from "./assets/images/coin/Coinbase.svg";
import ethereumLogo from "./assets/images/coin/eth-1.png";
import logo from "../src/assets/images/gold-logo.png";
import CreateCurvePage from './pages/createTokens';
import MyCurvsPage from './pages/myTokens';
import DetailPage from './pages/detailsPage';
import { socket } from './utils/socket';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// New imports for login functionality
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const projectId = 'woof_oracle';

const ETHMainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
};
const sepoliaTestnet = {
  chainId: 11155111,
  name: 'Sepolia test network',
  currency: 'SepoliaETH',
  explorerUrl: 'https://sepolia.etherscan.io',
  rpcUrl: 'https://1rpc.io/sepolia'
}
const baseNetwork = {
  chainId: 8453,
  name: 'Base Mainnet',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org',
  rpcUrl: 'https://base-rpc.publicnode.com'
}
const avaxNetwork = {
  chainId: 43114,
  name: 'Avalanche Network C-Chain',
  currency: 'AVAX',
  explorerUrl: 'https://snowtrace.io/',
  rpcUrl: 'https://1rpc.io/avax/c'
}
const arbitrumNetwrok = {
  chainId: 42161,
  name: 'Arbitrum One',
  currency: 'ETH',
  explorerUrl: 'https://explorer.arbitrum.io',
  rpcUrl: 'https://arbitrum.llamarpc.com'
}
const polygonNetwrok = {
  chainId: 137,
  name: 'Polygon Network',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon.llamarpc.com'
}

const metadata = {
  name: 'Fair Launch',
  description: 'It prevents rugs by making sure that all created tokens are safe',
  url: '...', // origin must match your domain & subdomain
  icons: [logo]
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: false, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

createWeb3Modal({
  ethersConfig,
  chains: [ETHMainnet, baseNetwork, avaxNetwork, arbitrumNetwrok, polygonNetwrok, sepoliaTestnet],
  chainImages: {
    1: ethereumLogo
  },
  connectorImages: {
    coinbaseWallet: coinbaseLogo,
    walletConnect: walletConnectLogo,
  },
  projectId
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    socket.on('connect', () => {});

    return () => {
        socket.off('connect');
    }
  }, []);

return (
  <div className="App">
    <MainContextProvider>
      <ToastContainer/>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route 
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
                <Route path='/' element={<DashboardPage />} />
                <Route path='/create_tokens' element={<CreateCurvePage />} />
                <Route path='/my_tokens' element={<MyCurvsPage />} />
                <Route path='/:chain/:id' element={<DetailPage/>}/>
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MainContextProvider>
  </div>
);
}

export default App;