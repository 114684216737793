import React, { useEffect, useRef, useState } from 'react';
import { createChart, ColorType, IChartApi, CandlestickData, HistogramData, Time, UTCTimestamp, LineData, ISeriesApi } from 'lightweight-charts';
import { Box } from '@mui/material';
import BigNumber from 'bignumber.js';

export interface ChartProps {
  data: {
    price: CandlestickData<Time>[];
    volume: HistogramData<Time>[];
  };
  latestPrice: string;
}

const Chart: React.FC<ChartProps> = ({ data, latestPrice }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candlestickSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const volumeSeriesRef = useRef<ISeriesApi<"Histogram"> | null>(null);
  const areaSeriesRef = useRef<ISeriesApi<"Area"> | null>(null);
  const isComponentMounted = useRef(true);
  const [processedData, setProcessedData] = useState<{
    price: CandlestickData<Time>[];
    volume: HistogramData<Time>[];
    area: LineData[];
  }>({ price: [], volume: [], area: [] });

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (chartContainerRef.current && !chartRef.current) {
      const handleResize = () => {
        if (chartRef.current && isComponentMounted.current) {
          chartRef.current.applyOptions({ width: chartContainerRef.current!.clientWidth });
        }
      };

      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 400,
        layout: {
          background: { type: ColorType.Solid, color: '#1E1E1E' },
          textColor: '#D9D9D9',
        },
        grid: {
          vertLines: { color: '#2B2B43' },
          horzLines: { color: '#2B2B43' },
        },
        rightPriceScale: {
          borderColor: '#2B2B43',
          scaleMargins: {
            top: 0.1,
            bottom: 0.2,
          },
          visible: true,
        },
        timeScale: {
          borderColor: '#2B2B43',
          timeVisible: true,
          secondsVisible: false,
        },
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#00ffff', // Cyan for up candles
        downColor: '#ff00ff', // Magenta for down candles
        borderVisible: false,
        wickUpColor: '#00ffff',
        wickDownColor: '#ff00ff',
        priceScaleId: 'right',
      });

      const areaSeries = chart.addAreaSeries({
        topColor: 'rgba(128, 0, 128, 0.5)', // Purple with opacity
        bottomColor: 'rgba(128, 0, 128, 0.0)', // Transparent purple
        lineColor: 'rgba(128, 0, 128, 1)', // Solid purple
        lineWidth: 2,
        priceScaleId: 'right',
        lastValueVisible: false,
        priceLineVisible: false,
      });

      const volumeSeries = chart.addHistogramSeries({
        color: '#00ffff', // Cyan for volume
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
      });

      chartRef.current = chart;
      candlestickSeriesRef.current = candlestickSeries;
      areaSeriesRef.current = areaSeries;
      volumeSeriesRef.current = volumeSeries;

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        if (chartRef.current && isComponentMounted.current) {
          chartRef.current.remove();
          chartRef.current = null;
          candlestickSeriesRef.current = null;
          areaSeriesRef.current = null;
          volumeSeriesRef.current = null;
        }
      };
    }
  }, []);

  useEffect(() => {
    const processedPriceData = data.price.map(item => ({
      ...item,
      open: new BigNumber(item.open).toNumber(),
      high: new BigNumber(item.high).toNumber(),
      low: new BigNumber(item.low).toNumber(),
      close: new BigNumber(item.close).toNumber(),
    }));

    const latestPriceNumber = new BigNumber(latestPrice).toNumber();
    const currentTime = Math.floor(Date.now() / 1000);

    // Update the last candle if it's from the current time period, otherwise add a new one
    if (processedPriceData.length > 0) {
      const lastCandle = processedPriceData[processedPriceData.length - 1];
      const candlePeriod = 300; // 5 minutes in seconds
      if (currentTime - (lastCandle.time as number) < candlePeriod) {
        lastCandle.close = latestPriceNumber;
        lastCandle.high = Math.max(lastCandle.high, latestPriceNumber);
        lastCandle.low = Math.min(lastCandle.low, latestPriceNumber);
      } else {
        processedPriceData.push({
          time: currentTime as Time,
          open: latestPriceNumber,
          high: latestPriceNumber,
          low: latestPriceNumber,
          close: latestPriceNumber,
        });
      }
    } else if (processedPriceData.length === 0) {
      // If there's no data, create the first candle
      processedPriceData.push({
        time: currentTime as Time,
        open: latestPriceNumber,
        high: latestPriceNumber,
        low: latestPriceNumber,
        close: latestPriceNumber,
      });
    }

    const areaData: LineData[] = processedPriceData.map(item => ({
      time: item.time,
      value: (item.low + item.high) / 2,
    }));

    setProcessedData({
      price: processedPriceData,
      volume: data.volume,
      area: areaData,
    });
  }, [data, latestPrice]);

  useEffect(() => {
    if (isComponentMounted.current && chartRef.current && candlestickSeriesRef.current && volumeSeriesRef.current && areaSeriesRef.current) {
      candlestickSeriesRef.current.setData(processedData.price);
      volumeSeriesRef.current.setData(processedData.volume);
      areaSeriesRef.current.setData(processedData.area);

      chartRef.current.timeScale().fitContent();

      // Dynamically set price format based on the data
      const minPrice = Math.min(...processedData.price.flatMap(item => [item.low, item.high, item.open, item.close]));
      const precision = Math.max(0, Math.ceil(-Math.log10(minPrice)) + 2);
      const minMove = Math.pow(10, -precision);

      candlestickSeriesRef.current.applyOptions({
        priceFormat: {
          type: 'price',
          precision: precision,
          minMove: minMove,
        },
      });

      areaSeriesRef.current.applyOptions({
        priceFormat: {
          type: 'price',
          precision: precision,
          minMove: minMove,
        },
      });
    }
  }, [processedData]);

  return <Box ref={chartContainerRef} sx={{ width: '100%', height: '400px' }} />;
};

export default Chart;